import React, { useEffect } from 'react';

import { navigate } from 'gatsby';
import LocalizedStrings from 'react-localization';
import store from 'store';
import { StringParam, useQueryParam } from 'use-query-params';

import { Layout } from '../components';
import UserList from '../components/UserList';

const strings = new LocalizedStrings({
    en: {
        operators: 'Operators',
        listOfOperators: 'A list of Admin, Staffs and Sponsors',
    },
    de: {
        operators: 'Moderatoren',
        listOfOperators: 'Liste der Administratoren, Mitarbeiter und Sponsoren',
    },
});

const UserListPage = () => {
    const loggedInUser = store.get('user') as Users.User;
    if (loggedInUser?.roles?.includes('staff')) navigate('/event-list?clientId=' + loggedInUser.client?._id);
    const [clientId, setClientId] = useQueryParam('clientId', StringParam);
    useEffect(() => {
        if (!clientId) navigate('/');
    }, []);

    return (
        <Layout title={strings.operators} subTitle={strings.listOfOperators}>
            <UserList
                clientId={clientId}
                filterRoles={['admin', 'sponsor', 'staff']}
                padding={true}
                isOperatorList={true}
            />
        </Layout>
    );
};

export default UserListPage;
